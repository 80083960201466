<template>
  <div class="view-root flex-column">
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <p class="common-title">与会人员列表</p>
      <el-button @click="clickShowDialog">批量导入</el-button>
    </div>
    <div class="table-margin-top flex-1-overflow-y">
      <div class="table-header table-margin-bottom">
        <div class="table-index">序号</div>
        <div class="table-name">姓名</div>
        <div class="table-mobile">手机号码</div>
        <div class="table-email">邮箱</div>
        <div class="table-email">门票类型</div>
        <div class="table-email">是否使用参好会</div>
      </div>
      <div
        v-for="(participant, index) in dataList"
        :key="index"
        class="table-item table-margin-bottom"
        style="font-weight: bold; margin-bottom: 25px"
      >
        <div class="table-index" style="font-weight: normal">
          {{ index + 1 }}
        </div>
        <div class="table-name">{{ participant.user.name }}</div>
        <div class="table-mobile">{{ participant.user.mobile }}</div>
        <div class="table-email" style="font-weight: normal">
          {{ participant.user.email }}
        </div>
        <div class="table-email" style="font-weight: normal">
          {{participant.ticket_type? participant.ticket_type: findTicket(participant.ticket_id) }}
        </div>
        <div class="table-email" style="font-weight: normal">
          {{participant.checkin_status? participant.checkin_status: '' }}
        </div>
      </div>
    </div>

    <!-- 批量导入名单 -->
    <el-dialog :visible.sync="dialogVisible" title="批量导入" width="60%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="选择门票种类">
          <el-select v-model="form.ticketType" placeholder="请选择">
            <el-option
              v-for="item in tickets"
              :key="item._id.$id"
              :label="item.type"
              :value="item._id.$id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="0px">
          <el-input
            type="textarea"
            :rows="10"
            placeholder="从excel复制列格式如下：姓名+手机+邮箱，后者手动输入以逗号、分号、|封隔的行列数据'"
            v-model="form.textarea"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button
          type="primary"
          @click="onSubmit"
          :disabled="!form.ticketType || !form.textarea"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import "../../assets/common/common.css";
import {
  getMeetingParticipants,
  addMeetingApplication,
  getTickets,
} from "../../api/api";

export default {
  name: "index",
  data() {
    return {
      meeting_id: this.$route.query.meeting_id,
      dataList: [],
      tickets: [],
      dialogVisible: false,
      form: {
        ticketType: "",
        textarea: "",
      },
    };
  },
  mounted() {
    this.fetchData();
    this.fetchTickets();
  },
  methods: {
    fetchData() {
      getMeetingParticipants(this.meeting_id).then((res) => {
        this.dataList = res.data.data;
      });
    },
    fetchTickets() {
      getTickets(this.meeting_id).then((res) => {
        this.tickets = res.data.data;
      });
    },
    findTicket(ticketId) {
        if (this.tickets.length) {
            const curTicket = this.tickets.find(item => item._id.$id === ticketId);
            return curTicket ? curTicket.type : '-'
        }
        return '-'
    },
    clickShowDialog() {
      this.dialogVisible = true;
    },
    onCancel() {
      this.dialogVisible = false;
      this.form.textarea = "";
    },
    onSubmit() {
      this.dialogVisible = false;
      const data = this.form.textarea
        .split(/\n+/)
        .map((item) => item.trim())
        .filter((item) => item)
        .map((item) => item.split(/\t|,|;|\|/))
        .filter((item) => item.length >= 2)
        .map((item) =>
          addMeetingApplication({
            meeting_id: this.meeting_id,
            ticket_id: this.form.ticketType,
            user_name: item[0] || "",
            user_mobile: item[1] || "",
            user_email: item[2] || "",
            open_id: "",
            application_channel: "from_import",
          })
        );
      Promise.allSettled(data).then((res) => {
        const filed = res.filter(
          (item) => item.status === "rejected" || item.value.data.code !== 0
        );
        if (filed.length) {
          this.$message.warn("部分数据导入失败");
        } else {
          this.form.textarea = "";
          this.$message.success("导入成功");
          this.fetchData();
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.table-name {
  flex: 3;
}

.table-mobile {
  flex: 3;
}

.table-email {
  flex: 3;
}
</style>
